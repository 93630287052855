<template>
  <b-container fluid>
    <div class="card border-top-0 rounded-10px shadow">
      <div class="card-header bg-white border-bottom-0 rounded-10px pt-4">
        <h5 class="text-brown">貨幣匯率信息</h5>
      </div>
      <div class="card-body bg-brown--light py-5 rounded-10px">
        <b-form>
          <div class="d-flex align-items-end">
            <div class="font-weight-bold text-nowrap mr-3 mr-lg-5 mb-2">人民幣匯率</div>

            <div class="mr-3">
              <div class="mb-2">
                <div class="font-weight-bold mb-1">人民幣</div>
                <small class="d-block mb-1">1.00 港元 = 人民幣</small>
                <small class="d-block text-danger font-weight-light mb-1" v-if="veeErrors.has('rmbRate')">{{ veeErrors.first('rmbRate') }}</small>
              </div>
              <div class="input-group">
                <input
                  type="text"
                  name="rmbRate"
                  class="form-control form-control-lg form-control--addon-right text-dark rounded-0"
                  required
                  v-validate="'decimal'"
                  v-model="rmbRate"
                >
                <span class="input-group-text input-group-text--addon-right">
                  <icon-yuan></icon-yuan>
                </span>
              </div>
            </div>

            <b-button
              variant="primary"
              class="text-nowrap ml-auto px-5"
              @click="updateRmbrate"
              :disabled="veeErrors.has('rmbRate')"
            >儲存</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-container>
</template>

<script>
import { getRmbrate, updateRmbrate } from '@/services/rmbrate.service'
import IconYuan from '@/components/icons/IconYuan.vue'

export default {
  name: 'rmb-rate',

  components: {
    'icon-yuan': IconYuan
  },

  data: () => ({
    rmbRate: 0
  }),

  mounted () {
    this.getRmbrate()
  },

  methods: {
    getRmbrate () {
      getRmbrate().then(
        res => {
          if (res.data.success) {
            this.rmbRate = res.data.data.rmbRate
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },

    updateRmbrate () {
      const data = {
        rmbRate: this.rmbRate
      }

      updateRmbrate(data).then(
        res => {
          if (res.data.success) {
            this.getRmbrate()
            this.makeToast('success', '貨幣匯率信息', '儲存成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },

    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    }
  }
}
</script>
